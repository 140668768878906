.bg-blur-orders {
   background-color: #068fff;
   backdrop-filter: blur(5px);
}

.bg-header-orders {
   background-color: #068fff;
   backdrop-filter: blur(5px);
}

.bg-header-orders h2 {
   color: #fff;
   font-size: 30px;
   font-weight: 700;
   letter-spacing: 2px;
}

.bg-header-orders small {
   color: #fff;
   font-size: 20px;
   font-weight: 700;
   letter-spacing: 1px;
}

/* Cards orders  */
.ecommerce-orders-card {
   border: 1px solid #adb5bd85;

}

.img-ecommerce-orders-card {
   width: 10px;
   height: 10px;
   object-fit: contain;
}


.title-ecommerce-orders-card {
   font-size: 18px;
   width: 100px;
   font-weight: 800;
}

.description-ecommerce-orders-card {
   font-size: 12px;
   font-weight: 500;
   line-height: 1.5;
   /* Altura de línea, ajusta según sea necesario */
   max-height: 3em;
   /* 2 líneas * altura de línea */
   overflow: hidden;
   position: relative;

}

.value-ecommerce-orders-card {
   font-size: 18px;
   font-weight: 700;
   color: #2ab731;
   letter-spacing: 1px;
}

.mark {
   padding: 0px 1px;
}



/* Card Orders */


:root {
   --card-line-height: 1.2em;
   --card-padding: 5px;
   --card-radius: 0.5em;
   --color-green: #0072ff;
   --color-gray: #e2ebf6;
   --color-dark-gray: #c4d1e1;
   --radio-border-width: 2px;
   --radio-size: 1.5em;
}

.card-list-orders {
   background-color: #fff;

   border-radius: 30px;
   position: relative;
   display: block;
   margin: 10px 5px;
}



/* 
.radio {
   font-size: inherit;
   margin: 0;
   display: none;
   position: absolute;
   right: calc(var(--card-padding) + var(--radio-border-width));
   top: calc(var(--card-padding) + var(--radio-border-width));
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
   .radio {
      appearance: none;
      background: #fff;
      border: var(--radio-border-width) solid var(--color-gray);
      border-radius: 50%;
      cursor: pointer;
      height: var(--radio-size);
      outline: none;
      transition: background 0.2s ease-out, border-color 0.2s ease-out;
      width: var(--radio-size);
   }

   .radio::after {
      border: var(--radio-border-width) solid #fff;
      border-top: 0;
      border-left: 0;
      content: "";
      display: block;
      height: 0.75rem;
      left: 25%;
      position: absolute;

      top: 50%;
      transform: rotate(45deg) translate(-50%, -50%);
      width: 0.375rem;
   }

   .radio:checked {
      display: inline;
      background: var(--color-green);
      border-color: var(--color-green);
   }

   .card-list-orders:hover .radio {
      border-color: var(--color-dark-gray);
   }

   .card-list-orders:hover .radio:checked {
      border-color: var(--color-green);
   }
} */

.card-list-orders-title {
   border: .5px solid #2323233a;
   border-radius: 15px;
   cursor: pointer;
   display: flex;
   flex-direction: column;
   padding: var(--card-padding);


   transition: border-color 0.2s ease-out;
}


/* .card-list-orders:hover .card-list-orders-title {
   border-color: var(--color-dark-gray);
}

.radio:checked~.card-list-orders-title {
   border: 2.5px solid var(--color-green);
}

.radio:focus~.card-list-orders-title {
   box-shadow: 0 0 0 2px var(--color-dark-gray);
}

.radio:disabled~.card-list-orders-title {
   color: var(--color-dark-gray);
   cursor: default;
}

.radio:disabled~.card-list-orders-title .card-list-orders-content{
   color: var(--color-dark-gray);
} */

.card-list-orders:hover .radio:disabled~.card-list-orders-title {
   border-color: var(--color-gray);
   box-shadow: none;
}

.card-list-orders:hover .radio:disabled {
   border-color: var(--color-gray);
}

.card-list-orders-content {
   color: #020202;
   font-size: 15px;
   font-weight: 700;
   line-height: 1em;
   text-align: left;
   margin-top: 2px;
}

.card-list-orders-description {
   color: #767676a3;
   font-size: 10px;
   font-weight: 500;
   line-height: 1em;
   text-align: left;
   margin-top: 2px;

}

.card-list-orders-cost {
   font-size: 2.5rem;
   font-weight: bold;
   padding: 0.5rem 0;
}

.slash {
   font-weight: normal;
}

.card-list-orders-cycle {
   font-size: 2rem;
   font-variant: none;
   border-bottom: none;
   cursor: inherit;
   text-decoration: none;
}

.hidden-visually {
   border: 0;
   clip: rect(0, 0, 0, 0);
   height: 1px;
   margin: -1px;
   overflow: hidden;
   padding: 0;
   position: absolute;
   white-space: nowrap;
   width: 1px;
}



/* Order Card  */
.order-track {
   margin-top: 10px;
   padding: 0 1rem;
   border-top: 1px dashed #d8d8d84b;
   padding-top: 10px;
   display: flex;
   flex-direction: column;
}

.order-track-step {
   display: flex;
   height: 4.5rem;
}

.order-track-step:last-child {
   overflow: hidden;
   height: 4rem;
}

.order-track-step:last-child .order-track-status span:last-of-type {
   display: none;
}

.order-track-status {
   margin-right: 1.5rem;
   position: relative;
}

.order-track-status-dot {
   display: block;
   width: 1.5rem;
   height: 1.5rem;
   border-radius: 50%;
   background: linear-gradient(45.6deg, #5fa0ff 2.11%, #0e42f4);
   background: linear-gradient(49deg,
         #0099ff,
         #0099ff 15%,
         #0099ff 25%,
         #1ec7ff 85%,
         #06acff 90%,
         #0099ff);
}

.order-track-status-line {
   display: block;
   margin: 0 auto;
   width: 2px;
   height: 4rem;
   background: linear-gradient(45.6deg, #5fa0ff 2.11%, #0e42f4);
   background: linear-gradient(49deg,
         #0099ff,
         #0099ff 15%,
         #0099ff 25%,
         #1ec7ff 85%,
         #06acff 90%,
         #0099ff);
}

.order-track-text-stat {
   font-size: 15px;
   font-weight: 800;
   margin-bottom: 3px;
}

.order-track-text-sub {
   font-size: 12px;
   font-weight: 300;
}

.order-track {
   transition: all 0.3s height 0.3s;
   transform-origin: top center;
}

/* Details summary */
figure {
   display: flex;
}

figure img {
   width: 4.5rem;
   height: 4.5rem;
   border-radius: 50%;
   border: 1px solid #0e44f445;
   margin-right: 1.5rem;
}

figure figcaption {
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
}

figure figcaption h4 {
   font-size: 20px;
   font-weight: 700;
}

figure figcaption h6 {
   color: #c4d1e1;
   font-size: 16;
   font-weight: 500;
}

figure figcaption h2 {
   font-size: 15px;
   font-weight: 500;
}

details article {
   opacity: 0;
}

details[open] article {
   animation: fadeIn .75s linear forwards;
}

@keyframes fadeIn {
   0% {
      opacity: 0;
   }

   100% {
      opacity: 1;
   }
}

summary {
   list-style: none;
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 10px 15px;
   font-weight: bold;
}

summary::after {
   content: '';
   width: 0;
   height: 0;
   border-top: 10px solid #15171b;
   border-inline: 7px solid transparent;
   transition: 0.2s;
}

details[open]>summary::after {
   transform: rotate(-180deg);
}

summary::-webkit-details-marker {
   display: none;
}

summary {
   color: #30353b;
   border-radius: 5px;
}

details[open] summary {
   border-radius: 5px 5px 0 0;
}

details {
   background: #ffffff;
   border: 1px dashed rgba(203, 203, 203, 0.405);
}

.extra-last-padding {
   padding: 2rem 0 15rem 0rem;
}




.zig-zag-border {
   background-color: #e8e8e841;
   border: 1px dashed rgba(203, 203, 203, 0.405);
   clip-path: polygon(0% 0%, 5% 5%, 10% 0%, 15% 5%, 20% 0%, 25% 5%, 30% 0%, 35% 5%, 40% 0%, 45% 5%, 50% 0%, 55% 5%, 60% 0%, 65% 5%, 70% 0%, 75% 5%, 80% 0%, 85% 5%, 90% 0%, 95% 5%, 100% 0%, 100% 100%, 95% 95%, 90% 100%, 85% 95%, 80% 100%, 75% 95%, 70% 100%, 65% 95%, 60% 100%, 55% 95%, 50% 100%, 45% 95%, 40% 100%, 35% 95%, 30% 100%, 25% 95%, 20% 100%, 15% 95%, 10% 100%, 5% 95%, 0% 100%);
}

.badge-success {
   color: #ffffff;
   background-color: #57de35;
}


.badge-pending {
   color: #ffffff;
   background-color: #29308f97;
}

.badge-processing {
   color: #ffffff;
   background-color: #101ede97;
}

.badge-danger {
   color: #ffffff;
   background-color: #f30b0bb9;
}

.nav-sp-item{
   color: #fff;
}
