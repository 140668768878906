.container-bar-menu {
   width: 800px;
   bottom: 0;
   padding: 2px;
}


@media (max-width: 768px) {

   .bar-menu {
      background-color: #000000ab;
      backdrop-filter: blur(5px);
      padding: 2px 12px;
      margin: 0px 0px;
      border: 1px solid #000000;
      border-radius: 20px;
      width: 400px;

   }

   .container-bar-menu {
      width: 100%;

      bottom: 0;
      padding: 2px;
   }

}


.bar-menu {
   background-color: #000000ab;
   backdrop-filter: blur(5px);
   padding: 2px 12px;
   margin: 0px 0px;
   border-radius: 20px;


}

.active-link {
   color: #45d900;
   /* Cambia el color según tu preferencia */
   transition: background-color 0.2s ease-out;
   /* Transición suave */
}

.active-link i {
   color: #ffffff;
   background: #00c6ff;
   background: -webkit-linear-gradient(to top, #0072ff, #00c6ff);
   background: linear-gradient(to top, #0072ff, #00c6ff);
   padding: 2px 8px;
   border-radius: 10px;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   -ms-border-radius: 10px;
   -o-border-radius: 10px;
}

.active-link small {
   background: linear-gradient(to top, #0072ff, #00c6ff);
   background-clip: text;
   -webkit-text-fill-color: transparent;
   font-weight: 900;
   padding: 2px 0px;
}


.no-active-link {
   color: rgba(255, 255, 255, 0.763);
}




.jello-horizontal {
   animation: jello-horizontal .3s linear both;
   -webkit-animation: jello-horizontal .3s linear both;
}

@keyframes jello-horizontal {
   0% {
      transform: scale3d(1, 1, 1);
      -webkit-transform: scale3d(1, 1, 1);
      -moz-transform: scale3d(1, 1, 1);
      -ms-transform: scale3d(1, 1, 1);
      -o-transform: scale3d(1, 1, 1);
   }

   30% {
      transform: scale3d(1.25, .75, 1)
   }

   40% {
      transform: scale3d(.75, 1.25, 1)
   }

   50% {
      transform: scale3d(1.15, .85, 1)
   }

   65% {
      transform: scale3d(.95, 1.05, 1)
   }

   75% {
      transform: scale3d(1.05, .95, 1)
   }

   100% {
      transform: scale3d(1, 1, 1)
   }
}


/* ----------------------------------------------

---------------------------------------------- */

.tracking-in-expand-forward-top {
   animation: tracking-in-expand-forward-top 0.4s linear both
}

@keyframes tracking-in-expand-forward-top {
   0% {
      letter-spacing: -.2em;
      transform: translateZ(-700px) translateY(-100px);
      opacity: 0
   }

   40% {
      opacity: .6
   }

   100% {
      transform: translateZ(0) translateY(0);
      opacity: 1
   }
}